var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-ver-info-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _vm.$perms("version_deviceVerInfo_insert")
                        ? _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v(" 添加 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.fetchData.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "设备规格码" },
                        model: {
                          value: _vm.queryForm.dCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "dCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.dCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "设备版本" },
                        model: {
                          value: _vm.queryForm.softwareVer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "softwareVer",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.softwareVer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.fetchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "left",
              label: "设备规格码",
              prop: "dCode",
              "show-overflow-tooltip": "",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "left",
              label: "设备版本",
              prop: "softwareVer",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "固件版本",
              prop: "firmwareVer",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.videoCodeFormat,
              label: "编码类型",
              prop: "videoCode",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持人形追踪",
              prop: "supportsHumanTracking",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持自定义语音",
              prop: "supportsCustomVoice",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持双频Wifi",
              prop: "supportsDualBandWifi",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持预置点",
              prop: "supportsFourPoint",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持4G",
              prop: "supports4g",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持云台",
              prop: "supportsPTZ",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持AI",
              prop: "supportsAi",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持云存储",
              prop: "supportsCloudStorage",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持Alexa",
              prop: "supportsAlexa",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "设备Alexa区域",
              prop: "regionAlexa",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "设备类型",
              prop: "deviceType",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "分辨率",
              prop: "resolution",
              "show-overflow-tooltip": "",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "检测类型",
              prop: "aiType",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持声光报警",
              prop: "supportsAlarm",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statusFormat,
              label: "是否支持夜视模式",
              prop: "supportsNightVision",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "屏幕通道数",
              prop: "screenChannels",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.netTypeFormat,
              label: "网络类型",
              prop: "networkType",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "其它功能",
              prop: "otherFeatures",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.timeFormat,
              label: "创建时间",
              prop: "createdTime",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.timeFormat,
              label: "更新时间",
              prop: "updatedTime",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "225",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.$perms("version_deviceVerInfo_edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$perms("version_deviceVerInfo_delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm.$perms("version_deviceVerInfo_copyAdd")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.copyAdd(row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add-device-ver-info", {
        ref: "deviceVerInfo",
        attrs: { title: _vm.title, visible: _vm.showAddDialog },
        on: {
          reloadData: _vm.reloadTableData,
          "update:visible": function ($event) {
            _vm.showAddDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }